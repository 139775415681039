.edge-animated {
  stroke-dasharray: 10;
  animation: dashdraw 25s linear infinite reverse;
  ::after {
    content: "<";
    position: absolute;
  }
}
@keyframes dashdraw {
  to {
    stroke-dashoffset: 500;
  }
}
