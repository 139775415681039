@tailwind base;
@tailwind components;
@tailwind utilities;

/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/montserrat-v25-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-300.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-regular.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/montserrat-v25-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-500.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/montserrat-v25-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-600.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("assets/fonts/montserrat-v25-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-italic.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes collapseHorizontal {
  0% {
    min-width: 0px;
    max-width: 0px;
    width: 0px;
  }
  100% {
    min-width: 400px;
    max-width: 40vw;
  }
}
